<template>
	<div class="pl20 pr20 pb20">
		<div>
			<div class="liveitem">
				<div class="fs16" style="font-weight: 600;">
					2021-06-10日直播数据
				</div>
				<div class="fs14 flex_r" style="margin: 15px 0px 40px;">
					<p>
						<span class="color-2">本场主题：</span>
						罗拉密码-年中大促服装专场
					</p>
					<p style="margin-left: 40px;">
						<span class="color-2">开播时间：</span>
						2021-06-10 00:00:11
					</p>
					<p style="margin-left: 40px;">
						<span class="color-2">直播时长：</span>
						1小时45分钟
					</p>
				</div>
				<div
					class="flex_r flex-pack-justify f_jc_c f_ai_c row_item"
					style="padding: 0px 25px;"
				>
					<div>
						<p class="fs14 color-2">
							本场销量
						</p>
						<p class="fs24 sessionval">
							37.45万件
						</p>
					</div>
					<div class="cut-line"></div>
					<div>
						<p class="fs14 color-2">
							本场销售额
						</p>
						<p class="fs24 sessionval">
							3508.79万元
						</p>
					</div>
					<div class="cut-line"></div>
					<div>
						<p class="fs14 color-2">
							本场平均价格
						</p>
						<p class="fs24 sessionval">
							¥93.69元
						</p>
					</div>
					<div class="cut-line"></div>
					<div>
						<p class="fs14 color-2">
							本场累计观看人数
						</p>
						<p class="fs24 sessionval">
							324.10万
						</p>
					</div>
					<div class="cut-line"></div>
					<div>
						<p class="fs14 color-2">
							本场商品数
						</p>
						<p class="fs24 sessionval">
							157
						</p>
					</div>
				</div>
			</div>
		</div>
		<p class="dotted-title mt30">本场直播商品</p>
		<e-table
			:tableCols="detailTableConf"
			:dataOrigin="dataOrigin"
		>
			<template #1>
				<div class="flex_r">
					<img
						class="pr10"
						height="40"
						width="40"
						src="//gw.alicdn.com/bao/uploaded/i1/2980888022/O1CN01kMxlkA2987vRQ02IF_!!2980888022-0-picasso.jpg"
						alt=""
					/>
					<div class="tl">
						【薇娅618专享】叮叮懒人菜老坛酸菜鱼半成品快手菜黑鱼450g*3袋
					</div>
				</div>
			</template>
			<template #6>
				<router-link tag="a" target="_blank" :to="{path:'/goodsDetailDataCenter'}">查看详情</router-link>
			</template>
		</e-table>
	</div>
</template>

<script>
import { detailTableConf } from '@data/anchorData';
export default {
	data() {
		return {
			detailTableConf,
			dataOrigin: {
				data: [{}]
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.liveitem {
	background: rgb(246, 246, 246);
	border-radius: 10px;
	margin-top: 20px;
	padding: 20px;
	position: relative;
}
.flex-pack-justify {
	justify-content: space-between;
}
.color-2 {
	color: #8b8b8d;
}
.liveitem .sessionval {
	margin-top: 20px;
	font-weight: 600;
}
.liveitem .cut-line {
	width: 1px;
	height: 40px;
	background: rgb(229, 229, 229);
}
.row_item > div {
	text-align: center;
}
.dataarea{
    flex: 1;
}
</style>